#outerItem{
    border-radius: 1vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 75vh;
    width: 75vh;
    margin-top: 15vh;
    /* box-shadow: -1.5vh 1.5vh 2.5vh rgba(0, 0, 0, 0.41); */
}

.postTitle{
    font-family: 'Poppins', sans-serif;
    font-size: 3vh;
    font-weight: 600;
    margin: 2vw;
}

.PostForm{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.sellItemInput{
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 10px 20px;
    margin-bottom: 2vh;
    margin: 2vh;
    font-size: 2.5vh;
    font-weight: 500;
    cursor: text;
    border: 3px solid whitesmoke;
    border-radius: 1vh;
    color: black;
    transition: all 0.3s ease;
    width: 50vh;
}

.sellItemInput:hover{
    border: 3px solid black;
}

.itemSubmitButton{
    padding: 10px 20px;
    margin-bottom: 2vh;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    /* background: linear-gradient(-135deg, #c850c0, red); */
    background: black;
    /* box-shadow: -1.5vh 1.5vh 2.5vh rgba(0, 0, 0, 0.41); */
    color: #fff;
    transition: all 0.3s ease;
    width: 100%;
}

.itemSubmitButton:hover{
    /* background: linear-gradient(-135deg, #c850c0, red); */
    background-color: #1f2934;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
}

.imagePreview{
    width: 50vh;
}

.entireUpload {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 10px 20px;
    margin-bottom: 2vh;
    margin: 2vh;
    font-size: 2.5vh;
    font-weight: 500;
    cursor: pointer;
    border: 3px solid whitesmoke;
    border-radius: 1vh;
    color: black;
    transition: all 0.3s ease;
    width: 50vh;
    flex-direction: column;
    font-family: sans-serif;
}

.entireUpload:hover{
    border: 3px solid black;
}

.entireUpload.drag-active {
    background-color: #e6f7ff;
    border-color: #1890ff;
}

.imagePreviewContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.imagePreviewWrapper {
    position: relative;
    display: inline-block;
}

.imagePreview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
}

.removeImageButton {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    padding: 0;
}

.removeImageButton:hover {
    background-color: red;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    #outerItem{
        width: 35vh;
        height: fit-content;
    }
    .postTitle{
        font-size: 1.5vh;
    }
    .PostForm{
        width: 20vh;
    }
    .sellItemInput{
        width: min-content;
        font-size: smaller;
    }
    .photoUpload{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        margin-left: 10vh;
    }
    .entireUpload{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}