.cartContainerCheckout {
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    margin-top: 5vh;
    flex-direction: column;
}

.divAroundCartItem {
    background-color: white;
    padding: 3vh;
    margin-top: 5vh;
}

.cartTitle {
    font-size: 4vh;
    font-weight: 900;
    margin: 1vh;
    text-align: center;
}

.cartImg {
    border-radius: 1vh 0 0 0;
    width: 20vw;
}

.cartDescription {
    font-family: 'Poppins', sans-serif;
    margin: 3vh;
    font-weight: 300;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: start;
    text-align: start;
    flex-direction: column;
}

.removeCartItem {
    padding: 3px 5px;
    margin-bottom: 2vh;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: #1f2934;
    transition: all 0.3s ease;
    width: 100%;
}

.removeCartItem:hover {
    background-color: red;
    color: white;
}

.summaryContainer {
    font-weight: 500;
    width: 80vw;
    margin-top: 3vh;
}

.cartSub {
    text-decoration: underline;
    margin-bottom: 3vh;
}

.cartPrice {
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: overline;
    text-decoration: underline;
}

.cartQuantityInput {
    width: 6vh;
    border: none;
}

.boldText {
    font-weight: 600;
}

.emptyText {
    font-family: 'Poppins', sans-serif;
    font-size: 4vh;
    text-align: center;
}

.cart1Container {
    display: flex;
    justify-content: center;
    text-align: center;
}

.likedPostsCart {
    margin-top: 20px;
}

.likedPostsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.likedPostItem {
    margin: 10px;
    text-align: center;
}

.likedPostImage {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
}

.emptyText {
    text-align: center;
    font-size: 24px;
}

.emptyCartLink {
    display: block;
    text-align: center;
    margin-top: 10px;
}


.emptyCartContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 30px;
}

.backToItemButton {
    padding: 3px 5px;
    margin-top: 2vh;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: #1f2934;
    transition: all 0.3s ease;
}

.backToItemButton:hover {
    background-color: black;
    color: white;
}

.mainCartContainer{
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-color: white;
    border-radius: 1vh;
}

.totalAmountContainer{
    margin-top: 20vh;
}

.sideWindowContainer{
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.innerContainer{
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    margin-right: 5vh;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .divAroundCartItem {
        width: 90vw;
        height: auto;
    }
    .cartImg {
        width: 70vw;
    }
    .cartContainerCheckout {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: auto;
    }
    .cartDescription {
        margin: 2vh 0;
        width: 90%;
    }
    .cartText {
        width: 90vw;
        font-size: 2vh;
        gap: 2vh;
    }
    .cartSub {
        margin-bottom: 2vh;
    }
    .summaryContainer {
        width: 90vw;
        background-color: white;
        margin-top: 5vh;
        /* margin-bottom: 10vh; */
    }
    .sideWindowContainer{
        display: flex;
        flex-direction: column;
    }
    .totalAmountContainer{
        margin-top: 0;
    }
    .innerContainer{
        margin-right: 0;
    }
}
