.post-container {
    padding-bottom: 10px;
    margin: 15px;
    list-style: none;
    border: 2px solid transparent;
    background-color: #fff;
    border-radius: 10px;
    --fa-style-family-classic: "Font Awesome 6 Pro";
}

.divAroundReviewButton{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    gap: 10px;
    padding: 5vh;
}

#dateButtons {
    display: flex;
   justify-content: space-between;

}

.post-body-text {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 2vh;
    font-family: 'Poppins', sans-serif;
}

.sidebar-toggle {
    text-align: center;
    padding: 5px 0px;
    border-top: 2px solid #ba3b0a;
    border-bottom: 2px solid #ba3b0a;
    width: 100%;
    background-color: #F5CDAC;
    cursor: pointer;
    color: #ba3b0a;
}

.sidebar-toggle:hover {
    background-color: #F5A563;
}

#profileLink{
    background: linear-gradient(-135deg, #c850c0, red);
    color: white;
    padding: 3px;
    text-decoration: none;
    text-transform: uppercase;
    border:#ba3b0a;
    border: 10px solid blue;
    border-color: #F57114;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
}

#profileLink:hover {
    background-color: #fff;
}

.EditDeleteButton{
    font-family: 'Poppins', sans-serif;
    background-color: black;
    color: #fff;
    padding: 10px 16px;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* width: 5vw; */
}

.EditDeleteButton:hover{
    background-color: red;
    color: white;
}

.postImages {
    width: auto;
    height: 20vh;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

/* .post-container:hover{
    box-shadow: -10px 15px 20px rgba(0, 0, 0, 0.261);
} */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .postImages{
        width: 15vh;
        height: auto;
        min-height: auto;
        max-width: max-content;
    }
    .content{
        width: 5vw;
    }
    .content img{
        width: auto;
    }
}


.Add-to-cart1{
    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;
}

#titleandEdit{
    display: none;
    /* display: flex;
    justify-content: space-between; */
}

.likesButton {
    font-size: 44px;
    border: none;
    background: none;
    box-shadow: none;
    cursor: pointer;
    height: 50px;
    width: 30px;
    z-index: 1;
    /* margin: -4vh;
    margin-left: -5vh;
    margin-top: inherit; */
    /* color: linear-gradient(-135deg, #c850c0, red); */
}

#receiptTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: small;
    font-weight: 100;
    display: flex;
    width: 100%;
    margin: 2vh;
    font-family: 'Poppins', sans-serif;
}

.likesButton:checked{
    color: #c850c0;
}

.likesNum{
    margin-left: 115px;
    width: 50vh;
    text-decoration: underline;
    font-size: 25px;
    margin-top: 41px;
}

.price-Addtocart {
    display: flex;
    justify-content: space-between;
    padding: inherit;
    flex-direction: inherit;
}

#likesNumandText{
    display: flex;
    justify-content: left;
    margin-top: 50px;
}

#thumbAndText{
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
}

.likesText{
    margin-top: 50px;
}

/* .EditDeleteButton{
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background: linear-gradient(-135deg, #c850c0, red);
    color: white;
    margin: 1vh;
} */

.divAroundImgLike{
    display: inline-block;
}

.priceForItem{
    font-family: Helvetica, sans-serif;
}

#liked{
    width: 100%;
    margin: 2vh;
    display: flex;
    justify-content: flex-end;
}

.buttonLinkImages{
    border: none;
    cursor: pointer;
    display: flex;
    background: transparent;
    /* flex-direction: row; */
    flex-direction: column;
}

.post-main-content{
    background: white;
    border-radius: 10px;
    margin-top: inherit;
    font-family: 'Poppins', sans-serif;
    width: auto;
    /* padding: 5vh; */
}

.profilePosts {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 20px; /* Space between items */
    margin-top: 5vh;
}

.reviewDivs{
    height: auto;
    margin: 5vh;
    display: flex;
    flex-direction: column-reverse;
}
