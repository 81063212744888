.reviewForm{
    width: auto;
    margin-top: 10vh;
    padding: 4vh;
    background-color: rgba(255, 255, 255, 0.352);
    border-radius: 10px;
    box-shadow: -10px 15px 20px rgba(0, 0, 0, 0.261);
}

.reviewInputs{
    display: flex;
    flex-direction: column;
}

.review-style-inputs{
    cursor: text;
}

.reviewStyleInputs{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    font-size: 22px;
    cursor: text;
    margin: 2vh;
    border: 3px solid rgba(211, 211, 211, 0.407);
}

.reviewStyleInputs:hover{
    border: 3px solid transparent;
}

.createReviewButton{
    background-color: #1f2934;
    color: white;
    padding: 10px 16px;
    border-radius: 1vh;
    cursor: pointer;
    border: none;
}

.createReviewButton:hover{
    background-color: #ff6600;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .reviewForm{
        width: auto;
        margin-top: 0;
        padding: 4vh;
        background-color: rgba(255, 255, 255, 0.352);
        border-radius: 10px;
        box-shadow: -10px 15px 20px rgba(0, 0, 0, 0.261);
    }
    .reviewStyleInputs {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px 20px;
        border-radius: 10px;
        border: none;
        font-size: 15px;
        border: 1.5px solid lightgray;
        margin: 1dvh;
    }
    #review-body{
        border: 1.5px solid lightgray;
    }
    .createReviewButton{
        margin-top: 3vh;
    }
}