@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html, body {
  display: grid;
  height: 100%;
  width: 100%;
  place-items: center;
  background: #f2f2f2;
}

::selection {
  background: #4158d0;
  color: #fff;
}

.wrapper {
  width: 380px;
  border-radius: 15px;
  margin-top: 70px;
  position: relative;
  z-index: 2;
}

.wrapper .title {
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  line-height: 70px;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 15px 15px 0 0;
  background: black;
  font-family: 'Poppins', sans-serif;
}

.wrapper form {
  padding: 5px 10px 0 10px;
  background-color: #1f2934;
  border-radius: 0 0 15px 15px;
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.wrapper form .field {
  height: 50px;
  width: 100%;
  position: relative;
  margin-bottom: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.wrapper form .field input {
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 17px;
  padding-left: 20px;
  border: 1px solid lightgrey;
  border-radius: 25px;
  transition: all 0.3s ease;
}

.wrapper form .field input:focus,
.wrapper form .field input:valid {
  border-color: #4158d0;
}

.wrapper form .field label {
  position: absolute;
  top: 50%;
  left: 20px;
  color: #999999;
  font-weight: 400;
  font-size: 17px;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}

.wrapper form .field input:focus ~ label,
.wrapper form .field input:valid ~ label {
  top: 0%;
  font-size: 16px;
  color: #4158d0;
  background: #fff;
  transform: translateY(-50%);
}

.wrapper form .content {
  display: flex;
  width: 100%;
  height: 50px;
  font-size: 16px;
  align-items: center;
  justify-content: space-evenly;
}

.wrapper form .content .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper form .content input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

.wrapper form .content label {
  font-family: 'Poppins', sans-serif;
  color: white;
  user-select: none;
  padding-left: 5px;
  font-size: smaller;
}

form .pass-link a,
form .signup-link a {
  color: #4158d0;
  font-family: Helvetica, sans-serif;
  text-decoration: none;
}

form .pass-link a:hover,
form .signup-link a:hover {
  text-decoration: underline;
}

form .field input[type="submit"],
form .buttonField {
  display: inline-block;
  padding: 10px 20px;
  margin-top: -10px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background: black;
  color: #fff;
  transition: all 0.3s ease;
  width: 100%;
}

form .field input[type="submit"]:hover,
form .buttonField:hover {
  background: white;
  color: black;
}

form .field input[type="submit"]:active,
form .buttonField:active {
  transform: scale(0.95);
}

#remember-me {
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.toggleButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  display: flex;
  margin-bottom: 2vh;
  gap: 1vh;
  align-items: center;
  color: white;
}

.passwordErrors {
  color: red;
}

#outer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.general-error{
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-family: sans-serif;
}

@media only screen and (max-width: 780px) {
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
