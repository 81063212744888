.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 60vh;
}

/* #searchbar-wrapper {
    display: inline-block;
    position: relative;
} */

#searchbar {
    font-size: 18px;
    font-weight: 500;
    cursor: text;
    transition: all 0.3s ease;
    width: inherit;
    height: 2vw;
}

.search-container {
    display: flex;
    align-items: center;
}

*:focus {
    outline: none;
}

.search-bar {
    width: 25vw;
    border: 1.5px solid rgba(0, 0, 0, 0.15);
    font-size: 1vw;
    display: flex;
    align-items: center;
    border-radius: 60px;
    padding: 10px 20px;
    font-family: 'Poppins', sans-serif;
    height: 2vh;
    max-height: 3.5vh;
}

.search-bar input {
    background: transparent;
    flex: 1;
    border: none;
    outline: none;
    padding: 24px 20px;
    font-size: 20px;
}

::placeholder {
    color: black;
}

/* .suggestions-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 60vh;
} */
.suggestions-dropdown {
    position: absolute;
    background: white;
    width: 60vh;
    margin-top: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 2vh;
    border-radius: 1vh;
}

.suggestions-dropdown li {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    cursor: pointer;
}

.suggestions-dropdown li:hover {
    background: #f0f0f0;
}

.suggestion-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
}

.searchText{
    font-size: x-small;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .search-bar {
        padding: revert-layer;
        height: 5px;
        width: 25vw;
        padding: 12px 10px;
        border: none;
        font-size: x-small;
    }
    .page-container{
        width: auto;
    }
    .splashPageImage{
        height: 20dvh;
    }
    #navMenu > span {
        width: auto;
    }
    .links-nav{
        font-size: xx-small;
    }
    .wrapper{
        width: 280px;
    }
}

@media only screen and (min-device-width: 420px) and (max-device-width: 780px) {
    .wrapper{
        width: 380px;
    }
}