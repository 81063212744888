.post-container {
    padding-bottom: 10px;
    background-color: transparent;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.post-body-text {
    font-family: 'Poppins', sans-serif;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    font-weight: 600;
}

.reviewImage{
    border: 3px solid blue;
}

.reviewTitle{
    /* margin-top: 20px; */
    font-size: 30px;
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    width: -webkit-fill-available;
}

.review-rating{
    margin-left: 30px;
}

#profileLink{
    background: linear-gradient(-135deg, #c850c0, red);
    color: white;
    padding: 3px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 5px;
}

#profileLink:hover {
    background-color: #fff;
}

#ProfileUsername{
    background-color: black;
    color: white;
    height: 35vh;
    width: 100vw;
    font-family: 'Poppins', sans-serif;
    font-size: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#ProfilePostsTitle{
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
}

#user-button-group {
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* padding: 10px; */
    padding: 35px;
    /* cursor: pointer; */
    font-family: 'Poppins', sans-serif;
    gap: 10px;
}

.user-button-group-button {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    border-radius: 0.8vw;
    border: none;
    background-color: black;
    color: white;
    width: 15vw;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

#user-button-group-button-left {
    /* border-right: none; */
    border-top-left-radius: 10px;
    border: none;
}

#user-button-group-button-right {
    border-left: none;
    border-bottom-right-radius: 10px;
}

.user-button-group-button:hover {
    /* box-shadow: -5px 12px 10px rgba(0, 0, 0, 0.41); */
    background-color: #1f2934;
    color: white;
}

/* .editDeleteButtonProfilePage{

} */

.PostIndexItemProfilePage{
    display: flex;
    flex-wrap: wrap;
}

.profilePostsProfilePage{
    display: flex;
    flex-wrap: wrap;
    gap: 5vh;
    justify-content: center;
}

.profileNameContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-top: auto;
}

.profileContainerReviews{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.containerAroundUserPosts{
    border: 3px solid white;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column-reverse;
    padding: 3vh;
}

.noLikes{
    height: 60vh;
    font-family: sans-serif;
}

.reviewText{
    height: 60vh;
    font-family: sans-serif;
}

.noPosts{
    height: 60vh;
    font-family: sans-serif;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    #ProfileUsername{
        width: -webkit-fill-available;
    }
    .reviewText{
        width: auto;
    }
    .user-button-group-button{
        width: -webkit-fill-available;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 1vh;
        font-size: x-small;
    }
}