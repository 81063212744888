form .updateReviewButton {
    /* display: inline-block;
    padding: 10px 20px;
    margin-bottom: 2vh;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background: linear-gradient(-135deg, #c850c0, red);
    color: #fff;
    transition: all 0.3s ease;
    width: 100%; */
    background-color: #1f2934;
    color: white;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    text-decoration: none;
}

.updateReviewButton:hover{
    background-color: #ff6600;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
}

.backProfile{
    background-color: #1f2934;
    color: white;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    text-decoration: none;
    margin-top: 5vh;
}

.backProfile:hover{
    background-color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
}

form .updateReviewButton:active {
    transform: scale(0.95);
}
