#post-index-page {
    display: flex;
    margin-bottom: 80px;
}

#post-index-container {
    margin: 15px auto;
    width: 100%;
}

#post-item-list {
    display: flex;
    margin-top: 1vh;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    text-align: center;
    background-color: white;
}

#post-index-sidebar-active {
    width: 300px;
    padding-left: 20px;
    border-left: 2px solid #ba3b0a;
}

#post-index-sidebar {
    display: none;
}

#sidebar-title {
    font-family: DejaVu Sans Mono, monospace;
    font-size: 24px;
}

#sidebar-content {
    padding-top: 10px;
    top: 110px;
    text-align: center;
    position: sticky;
    font-family: DejaVu Sans Mono, monospace;
}

#x-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    float: left;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAUhJREFUaEPtmOEOgjAMhMuTqk+mvqmpcckyB+x6Nwyx/BW67+vNsrDYya/l5PyWAr9OMBPIBMgO5BYiG0g//pcJXM3sQbeuXwCujSbg4JePwE0sEaqNCHh37hW0L6iSKPClvNcdShkR8OLtQgqJtubTzLxZQxcqoJag4B0mIqCSoOEZAVZCAs8KRCVk8AoBVEIKrxIYlZDDKwX2JKbAqwXWJHzS+du7XNCc33sZRMfoVt222/W9UvgZCRTYnoQc/mgBxbHjK/mjt5BcQi3QmzbetfpPLJVQCmyNyhmn2Pd2UgmMzPkpEgqBEfi16URvJ1YAgZ8iwQhE4OUSUQEGXioREVDAyyRQASW8RAIRaD+rKM82h39WUcK3SUC1kQTKQvDnv70zffU7XDsiAPDMvzUF5vd4e4VMIBMgO5BbiGwg/XgmQLeQLPACAzxiMbcB4aEAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-size: contain;
}

.splashPageImage {
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
}

.image-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.splashPageImage {
    width: 100%;
    height: 70dvh;
    object-fit: cover;
}

.containerAroundImages {
    display: flex;
    width: 100%;
}
