.reviewButtonEditDelete{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #1f2934;
    color: white;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    width: 8vw;
    height: 5vh;
}

.reviewButtonEditDelete:hover{
    background: gold;
    color: white;
}
.reviewButtonDelete{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #1f2934;
    color: white;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    width: 8vw;
    height: 5vh;
}

.reviewButtonDelete:hover{
    /* background: linear-gradient(-135deg, #c850c0, red); */
    background: red;
    color: white;
}

.reviewRating{
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    margin-right: 5vh;
    margin-top: -3vh;
}

.reviewContainer{
    margin: 15px auto;
    width: 100%;
    background-color: white;
    border-radius: 1vh;
    padding: 5vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.3);
}

.containerAroundTimeStar{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-family: sans-serif;
    margin-top: 5vh;
}

.reviewInfoSpan{
    font-size: x-small;
}

.reviewBody{
    font-family: sans-serif;
    font-size: small;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .reviewButtonDelete{
        width: -webkit-fill-available;
    }
    .reviewButtonEditDelete{
        width: -webkit-fill-available;
    }
}