.itemDescriptionContainer{
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 60vh; */
    width: auto;
    flex-direction: column;
    margin-top: 10vh;
    text-align: center;
    background-color: white;
}

.handlePostFactor{
    margin-top: 15vh;
}

.borderAroundImage {
    border-radius: 5vh;
    min-height: 30vh; 
    max-height: 40vh;
    width: inherit;
    padding: 5vh;
}

.selectedBody{
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: inherit;
    width: inherit;
    /* border-radius: 5vh; */
    margin: 1vh;
    padding: 1vh;
    /* box-shadow: -5px 15px 20px rgba(58, 58, 58, 0.41); */
}

.selectedBodyText{
    text-decoration: underline;
    font-family: 'Poppins', sans-serif;
}

.divAroundCartReview{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.addToCart{
    background-color: #1f2934;
    color: white;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

.addToCart:hover{
    background-color: #48a1ff;
}

.checkoutButtonItemIcon{
    background-color: #1f2934;
    color: white;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.checkoutButtonItemIcon:hover{
    text-decoration: underline;
    background-color: black;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}


.checkoutButtonItem{
    color: white;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

.checkoutButtonItem:hover{
    background-color: #48a1ff;
}

.reviewButton{
    background-color: #1f2934;
    color: white;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

.reviewButton:hover{
    background-color: #ff6600;
}

.cartItemDescription{
    background-color: #1f2934;
    color: white;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    text-decoration: none;
}

.cartItemDescription:hover{
    background-color: #f5e6cc;
    color: black;
}

.userProfilePageButton{
    border: none;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    color: #007185;
    background-color: transparent;
}

.divAroundUsername{
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.containerAroundSelectedBody{
    /* border: 3px solid #c850c0; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: inherit;
    width: 25vw;
    padding: inherit;
}

.selectedPostImage{
    width: fit-content;
    height: inherit;
    margin: auto;
    max-width: 40vh;
}

.notWorking{
    color: black;
    border: 5vh solid blue;
}

.postPriceItem{
    font-family: system-ui;
    font-weight: 500;
}

.bikeBioItem{
    font-family: 'Poppins', sans-serif;
}

.backButtonItem{
    background-color: #1f2934;
    color: white;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}
.backButtonItem:hover{
    background-color: #ff6600;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
}

.reviewsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin: 5vh;
}

.postItemContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: white;
    margin-bottom: 15vh;
    height: 55vh;
}

.itemNoReviews{
    margin-top: 0;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .itemDescriptionContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: -webkit-fill-available;
        height: auto;
        background: white;
    }
    .selectedPostImage{
        width: auto;
        height: 25dvh;
    }
    .bikeBioItem{
        overflow: auto;
    }
    .selectedBody{
        flex-direction: column;
    }
    .containerAroundSelectedBody{
        width: 75vw;
    }
}
.quantityLabel {
    margin-right: 10px;
}

.quantityInput {
    width: 50px;
    padding: 5px;
    font-size: 1rem;
    margin-bottom: 10px;
}
