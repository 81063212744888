.footerContainer{
    background-color: #1f2934;
    width: 100%;
    height: 50vh;
    color: white;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vh;
}

.aboutMeDiv{
    height: -webkit-fill-available;
    width: inherit;
    display: flex;
    gap: 1vh;
    flex-direction: column;
    font-size: 13px;
    justify-content: flex-start;
    align-items: flex-start;
}

.ReachMeLinks{
    height: -webkit-fill-available;
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5vh;
    gap: 1vh;
}

.emailMeLink{
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: Poppins, sans-serif;
    justify-content: flex-start;
    width: inherit;
    /* height: 5vh; */
}

.quickLinks{
    display: flex;
    justify-content: space-around;
    font-size: 18px;
}

.dropdownNoButton{
    font-family: 'Poppins', sans-serif;
    padding: 5px 10px;
    color: white;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
}

.dropdownNoButton:hover{
    background: transparent;
    font-size: 22px;
    border: 3px solid transparent;
}

.aboutMeText{
    font-family: 'Poppins', sans-serif;
    margin-top: 5vh;
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
}

.smallAboutMe{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 13px;
    font-weight: 100;
}

.emailMeButton{
    font-family: 'Poppins', sans-serif;
    padding: 5px 10px;
    border: none;
    background-color: white;
    color: #007185;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
    height: 6vh;
}

.emailMeButton:hover{
    background: #007185;
    border: none;
    color: white;
}

.emailMeButton:active {
    transform: scale(0.95);
}

.emailDev{
    font-family: 'Poppins', sans-serif;
}

.emailMeText{
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    text-align: start;
    font-size: 14px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .footerContainer{
        height: auto;
    }
    .aboutMeDiv{
        font-size: 1vh;
    }
    .aboutMeText{
        font-family: 'Poppins', sans-serif;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        font-size: larger;
        margin-top: 0;
    }
    .smallAboutMe {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: smaller;
        font-weight: 100;
    }
    .ReachMeLinks{
        margin-top: 0;
    }
    .quickLinks{
        font-size: 1.4vh;
    }
    .dropdownNoButton {
        font-family: 'Poppins', sans-serif;
        padding: 5px 10px;
        justify-content: center;
        font-size: xx-small;
    }
    .emailMeText{
        font-size: 1vh;
    }
    .emailMeButton{
        font-size: xx-small;
        height: auto;
    }
    .scrollToTopButton{
        margin-bottom: 3vh;
    }
    .emailMeLink{
        height: -webkit-fill-available;
        width: inherit;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 5vh;
        gap: 1vh;
    }
}