.purchaseContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 40px;
}

#xmark23{
    margin-left: 800px;
}

.checkoutButton{
    padding: 10px 20px;
    font-size: 10px;
    cursor: pointer;
    border: none;
    background-color: #1f2934;
    color: #fff;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 2vh;
    border-radius: 1vh;
}

.checkoutButton:hover{
    background-color: #007185;
}

.cartText{
    width: 45vh;
    font-size: 1vw;
}

.emptyCartLink{
    text-decoration: none;
    border: none;
    background: white;
}

.emptyCartText{
    border: none;
    color: #007185;
    padding: 5vh;
}

.emptyCartText:hover{
    text-decoration: underline;
}

.textCheckout{
    font-family: 'Poppins', sans-serif;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .textCheckout{
        font-size: large;
    }
    .cartText{
        width: auto;
        font-size: 3vw;
        margin-bottom: revert;
    }
    .checkoutButton{
        height: 5vh;
        margin: 1vh;
    }
}