.wrapper form .loginField{
    height: 50px;
    width: 100%;
    position: relative;
  }
  
  .wrapper form .loginField input{
    height: 100%;
    width: 100%;
    outline: none;
    font-size: 17px;
    padding-left: 20px;
    border: 1px solid lightgrey;
    border-radius: 25px;
    transition: all 0.3s ease;
  }
  
  form .loginField {
    display: inline-block;
    padding: .3vh 20px;
    margin-bottom: 2vh;
    font-size: 20px;
    font-weight: 500;
    cursor: text;
    border: none;
    border-radius: 5px;
    /* background: linear-gradient(-135deg, #c850c0, red); */
    /* background: black; */
    border: 0.13vw solid black;
    transition: all 0.3s ease;
    width: 100%;
  }
  
  form .loginFields {
    display: inline-block;
    padding: 10px 20px;
    margin-bottom: 2vh;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    /* background: linear-gradient(-135deg, #c850c0, red); */
    background: black;
    color: #fff;
    transition: all 0.3s ease;
    width: 100%;
  }
  
  form .loginFields:active {
    transform: scale(0.95);
    color: white;
  }
  
  form .loginFields:hover {
    background: white;
    color: #262626;
  }
  .background-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
@media only screen and (min-device-width: 320px) and (max-device-width: 780px) {
  .media-container .background-video {
    display: none;
  }
  
  .background-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}
@media only screen and (max-width: 750px) {
  .background-video {
    display: none;
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}