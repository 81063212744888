#navbarOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #1f2934;
  width: 100%;
  height: 5vw;
  z-index: 9999;
  padding: 0 2vw; /* Add padding to space out the contents */
}

.smallNavbar {
  height: 40px;
}

.content {
  display: flex;
  align-items: center;
  height: auto;
  width: 3vh;
}

#navMenu {
  cursor: pointer;
  margin-right: 2vw; /* Space out the menu from the search bar */
}

#navMenu > span {
  display: block;
  width: 28px;
  height: 2px;
  background-color: black;
}

#navMenu > span:not(:last-child) {
  margin-bottom: 7px;
}

#navMenu, #navMenu > span {
  transition: all .4s ease-in-out;
}

#navMenu.active {
  transition-delay: 0.8s;
  transform: rotate(65deg);
}

#navMenu.active > span:nth-child(2) {
  width: 0;
}

#navMenu.active > span:nth-child(1),
#navMenu.active > span:nth-child(3) {
  transition-delay: .4s;
}

#navMenu.active > span:nth-child(1) {
  transform: translateY(9px) rotate(-30deg);
}

#navMenu.active > span:nth-child(3) {
  transform: translateY(-9px) rotate(80deg);
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 15px;
  width: -webkit-fill-available;
}

.menu a {
  text-decoration: none;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin: 15px;
  background-color: white;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
}

.menu a:hover {
  background-color: transparent;
  color: black;
}

.menuSession {
  position: absolute;
  top: 100%;
  right: 0vh;
  background-color: #1f2934;
  box-shadow: -10px 15px 20px rgba(0, 0, 0, 0.261);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 15px;
}

.menuSession a {
  text-decoration: none;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin: 15px;
  border-radius: 10px;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
}

.menuSession a:hover {
  background-color: transparent;
  color: black;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

#navMenuSession {
  cursor: pointer;
}

#navMenuSession > span {
  display: block;
  width: 28px;
  height: 2px;
  background-color: black;
}

#navMenuSession > span:not(:last-child) {
  margin-bottom: 7px;
}

#navMenuSession, #navMenuSession > span {
  transition: all .4s ease-in-out;
  margin-right: 3vh;
}

#navMenuSession.active {
  transition-delay: 0.8s;
}

#navMenuSession.active > span:nth-child(2) {
  width: 0;
}

#navMenuSession.active > span:nth-child(1),
#navMenuSession.active > span:nth-child(3) {
  transition-delay: .4s;
}

#navMenuSession.active > span:nth-child(1) {
  transform: translateY(9px) rotate(-30deg);
}

#navMenuSession.active > span:nth-child(3) {
  transform: translateY(-9px) rotate(30deg);
}

.menu-span#span1.scrolled,
.menu-span#span2.scrolled,
.menu-span#span3.scrolled {
  background-color: white;
}

.menu-span#span1,
.menu-span#span2,
.menu-span#span3 {
  background-color: #757575;
}

#logoutButton {
  font-family: 'Poppins', sans-serif;
  padding: 3px 8px;
  border-radius: 10px;
  background: #1f2934;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

#logoutButton:hover {
  background-color: black;
  color: white;
}

.rightNav {
  margin-left: 10px;
  margin-right: 25px;
  font-family: 'Poppins', sans-serif;
  padding: 3px 8px;
  border-radius: 10px;
  background-color: transparent;
  color: white;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
}

.mySpan {
  color: white;
  background-color: white;
}

.dropDownImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5vw;
  width: 1.5vw;
}

.dropDownImage:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5vw;
  width: 2.5vw;
}

.links-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  flex-direction: row;
}

.scrollToTopButton {
  position: fixed;
  bottom: 2vw;
  right: 5vw;
  z-index: 9999;
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.scrollToTopButton:hover {
  background-color: white;
  color: black;
}

.loggedInAs {
  font-family: 'Poppins', sans-serif;
}

#searchbar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1; /* Allow search bar to grow */
}

#sessionNav{
  display: flex;
  gap: 1vh;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #navMenu {
    width: 6vw;
  }
  
  #navBarImage {
    height: 4vh;
    width: 4vh;
  }
  
  .search-bar {
    width: 80%;
    padding: 5px;
  }
  
  .menu-span {
    background-color: blue;
    width: 80%;
    height: 0.2vh;
    margin-bottom: 2px;
  }
  #navMenu > span:not(:last-child) {
    /* margin-bottom: 3px; */
    width: auto;
    height: 0.1dvh;
  }

  #navMenu > span {
    height: 0.1dvh;
  }
  #navbarOuter{
    height: 5vh;
  }
  #navMenuSession, #navMenuSession > span {
    margin-right: 0;
  }
  /* .rightNav{
    border: 3px solid red;
  } */
  .dropDownImage {
    width: 5vw;
    height: auto;
  }
  .menu a{
    margin: 0;
  }
  #logoutButton{
    font-family: 'Poppins', sans-serif;
    padding: 7px 10px;
    border-radius: 10px;
    background: #1f2934;
    border: none;
    color: white;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loggedInAs{
    margin-right: 1vh;
  }
}
