/* .starReview {
    display: flex;
    justify-content: flex-end;
} */
.starReview {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 5vh;
  }
  
  .starImage {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .starImage:hover {
    transform: scale(1.1);
  }
  
img{
    width: 3vh;
}
