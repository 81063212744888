body {
    margin: 0;
    padding: 0;
  }

  .mainpageContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    font-family: 'Poppins', sans-serif;
    opacity: 0;
    transition: opacity 1.5s ease-in-out;
    position: relative;
  }

  .mainpageContainer.animate {
    opacity: 1;
  }

  .mainImage {
    flex: 1;
    background-image: url(
'https://happyebikes.com/cdn/shop/files/Dan-Melody-Promo-SHot-Happy-Ebikes-Best-ebikes_1024x1024.jpg?v=1680715429'
    );
    background-size: cover;
    background-position: center;
    font-family: 'Poppins', sans-serif;
  }

  .rightTextPage {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    background-image: url(
      'https://images.unsplash.com/photo-1620802051782-725fa33db067?q=80&w=2768&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        );
    background-size: cover;
    background-position: center;
    font-family: 'Poppins', sans-serif;
    font-size: 3vh;
    color: aliceblue;
    position: relative;
  }


.mainImage, .rightTextPage {
  background-size: round;
}

.smallText{
    font-size: 2.5vh;
    width: 350px;
}

.loginDiv{
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;
  color: aliceblue;
  display: flex;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-size: 3vh;
  border-radius: 1vh;
  height: 55vh;
  width: 70%;
  background-color: rgba(0, 0, 0, 0.73);
  box-shadow: -10px 15px 20px rgba(0, 0, 0, 0.261);
  flex-direction: column;
  padding: 5vh;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}



.letsLog{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 5;
}

.loginSomething{
    display: flex;
    justify-content: center;
    align-self: center;
    width: 50%;
}

.loginLinks {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  text-decoration: none;
  padding: 10px 20px;
  margin: 1vh;
  margin-bottom: 2vh;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background: black;
  color: #fff;
  transition: all 0.3s ease;
  width: 100%;
  box-shadow: -10px 15px 20px rgba(255, 255, 255, 0.069);
}

.loginLinks:active {
  transform: scale(0.95);
}

.loginLinks:hover {
  transform: scale(0.95);
  background: white;
  color: black;
  box-shadow: -10px 15px 20px rgba(255, 255, 255, 0);
}
